import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Home from '@/views/home/index.vue'
import Login from '@/views/login/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'auto',
        name: 'Auto',
        component: () => import('@/views/auto/index.vue')
      },
      {
        path: 'headlineAppoint',
        name: 'HeadlineAppoint',
        component: () => import('@/views/headlineAppoint/index.vue')
      },
      {
        path: 'seckill',
        name: 'Seckill',
        component: () => import('@/views/seckill/index.vue')
      },
      {
        path: 'auction',
        name: 'Auction',
        component: () => import('@/views/auction/index.vue')
      },
      {
        path: 'searchSeat',
        name: 'SearchSeat',
        component: () => import('@/views/searchSeat/index.vue')
      },
      {
        path: 'quickReview',
        name: 'QuickReview',
        component: () => import('@/views/quickReview/index.vue')
      },
      {
        path: 'taskReview',
        name: 'TaskReview',
        component: () => import('@/views/taskReview/index.vue')
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/user/index.vue'),
        redirect: '/user/userAccount',
        children: [
          {
            path: 'userAccount',
            name: 'UserAccount',
            component: () => import('@/views/user/userAccount/index.vue')
          },
          {
            path: 'userAccountSafty',
            name: 'UserAccountSafty',
            component: () => import('@/views/user/userAccountSafty/index.vue')
          },
          {
            path: 'userAsset',
            name: 'UserAsset',
            component: () => import('@/views/user/userAsset/index.vue')
          },
          {
            path: 'userMember',
            name: 'UserMember',
            component: () => import('@/views/user/userMember/index.vue')
          },
          {
            path: 'userAuth',
            name: 'UserAuth',
            component: () => import('@/views/user/userAuth/index.vue')
          }
        ]
      },
      {
        path: 'message',
        name: 'Message',
        component: () => import('@/views/message/index.vue'),
        children: [
          {
            path: 'detail/:randomId',
            name: 'MessageDetail',
            component: () => import('@/views/message/detail/index.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/publishManage',
    name: 'PublishManage',
    component: Layout,
    redirect: '/publishManage/list',
    children: [
      {
        path: 'list',
        name: 'TaskList',
        component: () => import('@/views/publishManage/list/index.vue')
      },
      {
        path: 'publishTask',
        name: 'PublishTask',
        component: () => import('@/views/publishManage/publishTask/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_RUN_ENV == 'test' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('TOKEN')
  if (token) {
    
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if(to.path === '/login') {
      next()
    } else {
      next({path: '/login'})
    } 
  }
})

// 跳转同一路由报错问题
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => {})
};
VueRouter.prototype.replace = function(location) {
  return originalReplace.call(this, location).catch(err => {})
};

export default router
