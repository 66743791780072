<template>
	<div class="load_wrapper">
		<div :class="['loadimage', preview ? 'cursor-pointer':'']" @click="handlePreview">
			<img v-if="!isLoadErr" :src="imgUrl" :style="{'object-fit': mode}" @error="loadImgError"/>
			<div class="load_error" v-else>
				<div class="icon_error"></div>
			</div>
		</div>
		<!-- 预览图片 -->
		<el-dialog :visible.sync="dialogVisible" width="40%" show-close>
			<img class="preview-img" :src="imgUrl">
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'LoadImage',
		data() {
			return {
				imgUrl: '',
				isLoadErr: false,
				dialogVisible: false
			}
		},
		props: {
			mode: {
				type: String,
				default: 'cover'
			},
			src: {
				type: String,
				default: ''
			},
			default: {
				type: String,
				default: ''
			},
			preview: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			src(newVal) {
				if(newVal && newVal.trim()) {
					this.imgUrl = newVal
					this.isLoadErr = false
				}
			}
		},
		mounted() {
			if(!this.src || !this.src.trim()) {
				if(this.default) return this.imgUrl = this.default
				this.isLoadErr = true
			} else {
				this.imgUrl = this.src
			}
		},
		methods: {
			loadImgError() {
				if(this.default) {
					this.imgUrl = this.default
				} else {
					this.isLoadErr = true
				}
			},
			handlePreview() {
				if(!this.preview) return
				this.dialogVisible = true
			}
		}
	}
</script>

<style lang="scss" scoped>
.load_wrapper {
	width: 100%;
	height: 100%;
}
.loadimage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
	}
}
.load_error {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #ececec;
	.icon_error {
		width: 50%;
		height: 50%;
		max-width: 30px;
		max-height: 30px;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjkzNzk4ODM3MTQ4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9Ijg2NTkiIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MTIgMTcwLjY2NjY2N0MzMjQuMjY2NjY3IDE3MC42NjY2NjcgMTcwLjY2NjY2NyAzMjQuMjY2NjY3IDE3MC42NjY2NjcgNTEyczE1My42IDM0MS4zMzMzMzMgMzQxLjMzMzMzMyAzNDEuMzMzMzMzIDM0MS4zMzMzMzMtMTUzLjYgMzQxLjMzMzMzMy0zNDEuMzMzMzMzUzY5OS43MzMzMzMgMTcwLjY2NjY2NyA1MTIgMTcwLjY2NjY2N3ogbTAgNjQwYy0xNjQuMjY2NjY3IDAtMjk4LjY2NjY2Ny0xMzQuNC0yOTguNjY2NjY3LTI5OC42NjY2NjdzMTM0LjQtMjk4LjY2NjY2NyAyOTguNjY2NjY3LTI5OC42NjY2NjcgMjk4LjY2NjY2NyAxMzQuNCAyOTguNjY2NjY3IDI5OC42NjY2NjctMTM0LjQgMjk4LjY2NjY2Ny0yOTguNjY2NjY3IDI5OC42NjY2Njd6IiBmaWxsPSIjYmZiZmJmIiBwLWlkPSI4NjYwIj48L3BhdGg+PHBhdGggZD0iTTUxMiA0NDhjLTEyLjggMC0yMS4zMzMzMzMgOC41MzMzMzMtMjEuMzMzMzMzIDIxLjMzMzMzM3YyMTMuMzMzMzM0YzAgMTAuNjY2NjY3IDguNTMzMzMzIDIxLjMzMzMzMyAyMS4zMzMzMzMgMjEuMzMzMzMzczIxLjMzMzMzMy04LjUzMzMzMyAyMS4zMzMzMzMtMjEuMzMzMzMzVjQ2OS4zMzMzMzNjMC0xMC42NjY2NjctOC41MzMzMzMtMjEuMzMzMzMzLTIxLjMzMzMzMy0yMS4zMzMzMzN6TTUxMiAzMjBjLTEyLjggMC0yMS4zMzMzMzMgMTAuNjY2NjY3LTIxLjMzMzMzMyAyMS4zMzMzMzN2NDIuNjY2NjY3YzAgMTIuOCA4LjUzMzMzMyAyMS4zMzMzMzMgMjEuMzMzMzMzIDIxLjMzMzMzM3MyMS4zMzMzMzMtMTAuNjY2NjY3IDIxLjMzMzMzMy0yMS4zMzMzMzN2LTQyLjY2NjY2N2MwLTEyLjgtOC41MzMzMzMtMjEuMzMzMzMzLTIxLjMzMzMzMy0yMS4zMzMzMzN6IiBmaWxsPSIjYmZiZmJmIiBwLWlkPSI4NjYxIj48L3BhdGg+PC9zdmc+);
		background-size: cover;
		background-repeat: no-repeat;
	}
}
.preview-img {
	width: 100%;
}
.cursor-pointer {
	cursor: pointer;
}
</style>