<template>
  <div>
    <label :for="inputId">
      <div v-if="!$slots.default">
        <LoadImage v-if="showUploadImg && currentImg" class="cur-img" :src="currentImg"></LoadImage>
        <div class="upload-img" v-else>
          <img src="@/assets/images/add-plus.png">
          <div>添加图片</div>
        </div>
      </div>
      <slot></slot>
    </label>
    <input :id="inputId" type="file" accept="image/*" style="display: none;" @change="chooseImage">
  </div>
</template>

<script>
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import LoadImage from '@/components/LoadImage'
export default {
  components: {
    LoadImage
  },
  data() {
    return {
      currentImg: '',
      inputId: ''
    }
  },
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    // 是否显示上传后的图片
    showUploadImg: {
      type: Boolean,
      default: true
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    imgUrl: {
      handler(newVal) {
        if(newVal) this.currentImg = newVal
      },
      immediate: true
    }
  },
  created() {
    // 生成不同input id
    this.inputId = 'input_'+(parseInt(Math.random()*10000) + Date.now()).toString()
  },
  methods: {
    async chooseImage(e) {
      console.log('选择图片',e)
      const fileList = e.target.files || []
      if(fileList.length <= 0) return
      if(this.autoUpload) {
        const { uploadImageAsync } = await uploadImageUtil()
        uploadImageAsync(fileList[0]).then(res => {
          console.log('上传成功', res)
          this.currentImg = res.url
          this.$emit('success', res)
        }).catch(err => {
          this.$tips({message: '上传图片失败', type: 'error'})
          this.$emit('fail', err)
        })
      } else {
        this.$emit('success', fileList)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
}
.cur-img {
  width: 104px;
  height: 104px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: 1px dashed var(--theme-color);
  }
}
// 上传图片
.upload-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  border: 1px dashed #D9D9D9;
  background-color: #FAFAFA;
  font-size: 14px;
  color: #505E5C;
  cursor: pointer;
  &:hover {
    border: 1px dashed var(--theme-color);
  }
  img {
    width: 18px;
    height: 18px;
  }
}
</style>